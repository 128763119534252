/* app/controls/control.gallery-rotator */

import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';

let $homeHeroRotators;
let $scrollTo;
let $homeHeroVideoControl;

const arrowShape = {
	x0: 10,
	x1: 60, y1: 50,
	x2: 65, y2: 45,
	x3: 20
};

const homeHeroRotatorSettings = {
	arrowShape: arrowShape,
	wrapAround: true,
	lazyLoad: 2,
	pageDots: false,
	pageDots: true
};

var HomeHeroRotator = {
	init: function ($elms) {

		$homeHeroRotators = $elms;

		for (let i = 0; i < $homeHeroRotators.length; i++) {
			HomeHeroRotator._initFlickity($homeHeroRotators[i]);
		}
	},

	_initFlickity: function (homeHeroRotator) {
		const thisFlick = new Flickity(homeHeroRotator, homeHeroRotatorSettings);
	}
};

var ScrollToSection = {
	init: function ($elms) {
		$scrollTo = $elms;

		ScrollToSection._initEvents();

	},

	_initEvents: function () {
		$scrollTo.click(function () {
			ScrollToSection._scrollEvent($scrollTo);
		});

	},

	_scrollEvent: function ($scrollTo) {
		let target = $scrollTo.data('scroll');
		let headerHeight = $('.js-header').innerHeight();

		if (target.length) {
			target = $('[data-anchor=' + target + ']');

			$('html,body').stop().animate({
				scrollTop: target.offset().top - headerHeight //offsets for fixed header
			}, 'linear');
		}
	}
};

var HomeHeroVideo = {
	init: function ($elm) {
		HomeHeroVideo._toggleAudio($elm);
	},

	_toggleAudio: function ($control) {
		$control.click(function () {
			const video = $('.js-home-hero-video');
			console.log('play');
			$(this).find('span').toggleClass('iconf-sound iconf-mute');
			if (video.prop('muted')) {
				video.prop('muted', false);
			} else {
				video.prop('muted', true);
			}
		});
	}
};

export { HomeHeroRotator, ScrollToSection, HomeHeroVideo };

//var textAnimation = {
//	init: function ($textAnimation) {
//		const $textToAnimate = $textAnimation;
//		textAnimation._animateText($textToAnimate);
//	},

//	_animateText: function ($textToAnimate) {
//		for (let i = 0; i < $textToAnimate.length; i++) {

//			let order = $textToAnimate[i].dataset.animation * 100;

//			setTimeout(() => {
//				$textToAnimate[i].style.transform = 'translateY(0)';
//				$textToAnimate[i].style.opacity = '1';
//				$textToAnimate[i].style.transition = 'all 0.4s linear ' + order + 'ms';
//			}, 300);

//		}
//	}
//}

