/* util/util.google-map */

import { Loader } from '@googlemaps/js-api-loader';

// If a custom theme for the map has been created, add the JSON from Snazzy Maps to
// the 'control.google-map-styles.js' file and uncomment the line below
import { MapStyles } from 'Controls/control.google-map-styles';

// The API key is managed in the CMS
// In development we use the API key from the 'Whitesite' account in the Terabyte Stats Google account Developer Console
// This MUST be updated to an API key from the client account (attached to their billing account) prior to go-live
const apiKey = document.querySelector('body').dataset.gmapKey;
let maps;

const GoogleMapUtil = {
	init: function (elms) {

		maps = elms;

		if (apiKey === null || typeof apiKey === 'undefined' || apiKey === '') {
			// Can't do anything without an API Key
			return;
		}

		if (maps.length) {

			const loader = new Loader({
				apiKey: apiKey,
				version: 'weekly'
			});

			loader.load().then(GoogleMapUtil._initMaps);
		}
	},

	_initMaps: function (google) {

		for (let i = 0; i < maps.length; i++) {
			const mapCanvas = maps[i];
			const zoom = parseInt(mapCanvas.dataset.zoom, 10) || 12;
			const centerCoords = GoogleMapUtil._getCoords(mapCanvas.dataset.center);
			const markerCoords = GoogleMapUtil._getCoords(mapCanvas.dataset.marker);

			if (centerCoords !== null) {
				const centerLatLng = new google.maps.LatLng(centerCoords[0], centerCoords[1]);

				const mapOptions = {
					center: centerLatLng,
					draggable: !window.matchMedia('(pointer: coarse)').matches,
					mapId: `gmap${i}`,
					mapTypeControl: true,
					panControl: false,
					scrollwheel: false,
					streetViewControl: false,
					zoom: zoom,
					zoomControl: true,
					zoomControlOptions: {
						style: google.maps.ZoomControlStyle.LARGE,
						position: google.maps.ControlPosition.RIGHT_BOTTOM
					}
				};

				const map = new google.maps.Map(mapCanvas, mapOptions);

				if (MapStyles !== 'undefined') {
					const styles = new google.maps.StyledMapType(MapStyles, { name: 'Custom' });
					map.mapTypes.set('custom', styles);
					map.setMapTypeId('custom');
				}

				if (markerCoords !== null) {
					const markerLatLng = new google.maps.LatLng(markerCoords[0], markerCoords[1]);
					const marker = new google.maps.Marker({
						position: markerLatLng,
						map: map
					});
				}
			}
		}
	},

	_getCoords: function (latLng) {

		if (latLng === null || typeof latLng === 'undefined' || latLng === '') {
			return null;
		}

		let coords = latLng.split(',');
		coords = coords.map(function (item) {
			return parseFloat(item.trim());
		});

		return coords;
	}
};

export { GoogleMapUtil };
