/* app/ui/back-to-top/back-to-top */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';

var $footerListToggler;
var $footerList;

var footer = {
	init: function () {
		//$window = $(window);
		$footerListToggler = $('.js-footer-list-toggler');
		$footerList = $('.js-footer-list');

		footer._initStatus();
		$footerListToggler.on('click', footer._initToggle());
	},

	_initStatus: function () {
		MediaQueries.register([
			{
				queries: MediaQueries.queries['megamenu--xsmall'],
				match: function () {
					$footerListToggler.attr('aria-expanded', false);
					$footerList.slideUp();
					$footerList.attr('aria-expanded', false);
				},
				unmatch: function () {
					$footerListToggler.attr('aria-expanded', true);
					$footerList.slideDown();
					$footerList.attr('aria-expanded', true);
				}
			}
		]);
	},

	_initToggle: function () {
		MediaQueries.register([
			{
				queries: MediaQueries.queries['megamenu--xsmall'],
				match: function () {
					$footerListToggler.on('click touchstart', function () {
						$(this).next($footerList).slideToggle();
						if ($(this).attr('aria-expanded') === 'true') {
							$(this).attr('aria-expanded', false);
						} else {
							$(this).attr('aria-expanded', true);
						}
						if ($(this).next($footerList).attr('aria-expanded') === 'true') {
							$(this).next($footerList).attr('aria-expanded', false);
						} else {
							$(this).next($footerList).attr('aria-expanded', true);
						}
						return false;
					});
				},
				unmatch: function () {
					$footerListToggler.on('click', function (event) {
						event.preventDefault();
					});
				}
			}
		]);
	},


};

export { footer };
