/* app/controls/control.pathway-carousel */

import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'flickity-imagesloaded';
import { subscribe } from 'Util/pubsub';

const selectors = {
	rotator: '.js-pathway-carousel'
};

const dataSelectors = {
	flickity: 'flickity-instance'
};

const flickitySettings = {
	prevNextButtons: true,
	arrowShape: {
		x0: 10,
		x1: 60, y1: 50,
		x2: 65, y2: 45,
		x3: 20
	},
	autoPlay: false,
	wrapAround: true,
	cellAlign: 'left',
	pageDots: false,
	draggable: true
};

var module = {
	init: function ($elms) {
		//console.log($elms);

		for (let i = 0; i < $elms.length; i++) {
			module._initFlickity($elms.eq(i));
		}

		subscribe('/html-dom/change', module._reset);
		subscribe('/window/resize', module._toggleArrows);

		module._toggleArrows();
	},

	_initFlickity: function ($rotator) {
		const thisFlick = new Flickity($rotator[0], flickitySettings);
		$rotator.data(dataSelectors.flickity, thisFlick);
	},

	_reset: function ($container) {
		$container = $container || $(document);
		let $rotators = $container.find(selectors.rotator);

		for (let i = 0; i < $rotators.length; i++) {
			let $rotator = $rotators.eq(i);
			let flick = $rotator.data(dataSelectors.flickity);
			if (flick?.resize) {
				flick.resize();
			}
		}
	},

	_destroyFlickity: function ($rotator) {

		let flick = $rotator.data(dataSelectors.flickity);

		flick.destroy();
		//const thisFlick = new Flickity($rotator[0], flickitySettings);
		//$rotator.data(dataSelectors.flickity, thisFlick);
		//$rotator.Flickity('destroy');
	},

	_toggleArrows: function () {
		let $rotators = $(selectors.rotator);
		for (let i = 0; i < $rotators.length; i++) {
			let $rotator = $rotators.eq(i);
			let $slides = $rotator.find('.js-carousel-item');

			let windowWidth = $(window).width();
			let enabled = true;

			if (windowWidth > 1024) {
				if ($slides.length <= 4) {
					enabled = false;
				}
			} else if (windowWidth <= 1024 && windowWidth > 769) {
				if ($slides.length <= 3) {
					enabled = false;
				}
			} else if (windowWidth <= 768 && windowWidth > 481) {
				if ($slides.length <= 2) {
					enabled = false;
				}
			} else if (windowWidth <= 480) {
				if ($slides.length <= 1) {
					enabled = false;
				}
			}

			if (enabled) {
				if (!$rotator.hasClass("flickity-enabled")) {
					module._initFlickity($rotator);
				}
			} else {

				if ($rotator.hasClass("flickity-enabled")) {
					module._destroyFlickity($rotator);
				}
			}
		}
	}
};

export { module as PathwayCarousel };
